import React from 'react'
import AddItinerary from './views/addItinerary/AddItinerary'
import EditItinerary from './views/editItinerary/EditItinerary'
import Itineraries from './views/itineraries/Itineraries'
import Merchants from './views/merchants/Merchants'
import AddImage from './views/addImage/AddImage'
import Leaderboard from './views/leaderboard/Leaderboard'
import ManageGameImages from './views/manageGameImages/ManageGameImages'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))

export const MyRoutes = {
  DASHBOARD: '/dashboard',
  ITINERARIES: '/itineraries',
  ADD_ITINERARY: '/add-itinerary',
  EDIT_ITINERARY: '/edit-itinerary',
  RECOVER_PASSWORD: '/recover-password',
  RESET_PASSWORD: '/reset-password',
  LOGIN: '/login',
  ADD_UPLOAD: '/add-upload',
  LEADERBOARD: '/leaderboard',
  MANAGE_UPLOADS: '/manage-uploads',
  REGISTER: '/signup',
  NOT_FOUND: '/404',
  INTERNAL_SERVER_ERROR: '/500',
}

const routes = [
  { path: '/', exact: true, name: 'Dashboard', element: Itineraries },
  { path: MyRoutes.DASHBOARD, name: 'Dashboard', element: Itineraries },
  { path: MyRoutes.MERCHANTS, name: 'Partners', element: Merchants },
  { path: MyRoutes.ITINERARIES, name: 'Itinerari', element: Itineraries },
  { path: MyRoutes.ADD_ITINERARY, name: 'Crea itinerario', element: AddItinerary },
  { path: MyRoutes.MANAGE_UPLOADS, name: 'Manage Images', element: ManageGameImages },
  { path: MyRoutes.ADD_UPLOAD, name: 'Add Image', element: AddImage },
  { path: MyRoutes.LEADERBOARD, name: 'Leaderboard', element: Leaderboard },
  {
    path: `${MyRoutes.EDIT_ITINERARY}/:itineraryId`,
    name: 'Modifica itinerario',
    element: EditItinerary,
  },
]

export default routes
