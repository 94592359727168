import React, { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import './i18n/config'
import { MyRoutes } from './routes'
import './scss/style.scss'
import { get, post } from './services/http-service'
import { login, setBalance } from './slices/userSlice'
import { AUTH_TOKEN_KEY, ENDPOINT_GET_BALANCE, ENDPOINT_VALIDATE_TOKEN } from './variables'
import ResetPassword from './views/resetPassword/ResetPassword'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const Register = React.lazy(() => import('./views/register/Register'))
const RecoverPassword = React.lazy(() => import('./views/recoverPassword/RecoverPassword'))
const Page404 = React.lazy(() => import('./views/page404/Page404'))
const Page500 = React.lazy(() => import('./views/page500/Page500'))

const App = () => {
  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.user.token)
  useEffect(() => {
    async function validateToken() {
      const authToken = localStorage.getItem(AUTH_TOKEN_KEY)

      if (authToken) {
        try {
          const validationRes = await post({
            endpoint: ENDPOINT_VALIDATE_TOKEN,
            bearerToken: authToken,
          })
          dispatch(
            login({
              id: validationRes.id,
              email: validationRes.email,
              username: validationRes.username,
              token: authToken,
            }),
          )

          console.log('access token valid')
        } catch (err) {
          alert('Accesso scaduto')
        }
      }
    }

    validateToken()
  }, [])

  useEffect(() => {
    async function fetchBalance() {
      try {
        const balancePayload = await get({
          endpoint: ENDPOINT_GET_BALANCE,
          bearerToken: userToken,
        })

        dispatch(setBalance(balancePayload))
      } catch (err) {
        console.log(err?.message ?? err)
      }
    }

    dispatch(setBalance(0))

    if (!!userToken) {
      fetchBalance()
    }
  }, [userToken])

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path={MyRoutes.LOGIN} name="Login Page" element={<Login />} />
          <Route
            exact
            path={`${MyRoutes.REGISTER}/:token`}
            name="Register Page"
            element={<Register />}
          />
          <Route
            exact
            path={MyRoutes.RECOVER_PASSWORD}
            name="Recover Password Page"
            element={<RecoverPassword />}
          />
          <Route
            exact
            path={`${MyRoutes.RESET_PASSWORD}/:token`}
            name="Reset Password Page"
            element={<ResetPassword />}
          />
          <Route exact path={MyRoutes.NOT_FOUND} name="Page 404" element={<Page404 />} />
          <Route
            exact
            path={MyRoutes.INTERNAL_SERVER_ERROR}
            name="Page 500"
            element={<Page500 />}
          />
          <Route
            path="*"
            name="Home"
            element={userToken ? <DefaultLayout /> : <Navigate to={MyRoutes.LOGIN} />}
          />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
