// URLS
export const API_DOMAIN = 'https://api.zonzoapp.com'
// export const API_DOMAIN = 'http://localhost:3000'

// AUTH
export const ENDPOINT_LOGIN = `${API_DOMAIN}/auth/merchant-login`
export const ENDPOINT_VALIDATE_TOKEN = `${API_DOMAIN}/auth/validate-token`
export const ENDPOINT_SIGNUP = `${API_DOMAIN}/auth/merchant-signup`
export const ENDPOINT_RECOVER_PASSWORD = `${API_DOMAIN}/auth/request-merchant-new-password`
export const ENDPOINT_RESET_PASSWORD = `${API_DOMAIN}/auth/reset-password`

// ITINERARIES
export const ENDPOINT_GET_CREATED_ITINERARIES = `${API_DOMAIN}/itineraries/created`
export const ENDPOINT_GET_ITINERARY_COMPLETE = `${API_DOMAIN}/itineraries/get-complete`
export const ENDPOINT_POST_CREATE_ITINERARY = `${API_DOMAIN}/itineraries/create`
export const ENDPOINT_PATCH_UPDATE_ITINERARY = `${API_DOMAIN}/itineraries/update`
export const ENDPOINT_GET_ITINERARY_STRIPE = `${API_DOMAIN}/itineraries/get/{id}/stripe`
export const ENDPOINT_PATCH_UPDATE_ITINERARY_STRIPE = `${API_DOMAIN}/itineraries/update/{id}/stripe`
export const ENDPOINT_DELETE_ITINERARY = `${API_DOMAIN}/itineraries/delete`

// TAGS
export const ENDPOINT_GET_TAGS = `${API_DOMAIN}/tags/all`

// WALLET
export const ENDPOINT_GET_BALANCE = `${API_DOMAIN}/wallet/balance`
export const ENDPOINT_TRANSFER_TO = `${API_DOMAIN}/wallet/transfer-to`
export const ENDPOINT_GET_CHECK_RECEIPT = `${API_DOMAIN}/wallet/receipt`

// MERCHANT
export const ENDPOINT_GET_QR_CODE = `${API_DOMAIN}/merchant/qr`
export const ENDPOINT_GET_MERCHANTS = `${API_DOMAIN}/merchant/get`

// PUBLIC
export const GAME_IMAGES_PATH = `${API_DOMAIN}/gameImages`
export const ENDPOINT_POST_UPLOAD_GAME_IMAGE = `${API_DOMAIN}/uploads/upload-image`
export const ENDPOINT_DELETE_GAME_IMAGE = `${API_DOMAIN}/uploads/delete-image`

// STORAGE
export const AUTH_TOKEN_KEY = 'authTokenMerchant'
export const ENDPOINT_GET_GAME_IMAGES_NAMES = `${API_DOMAIN}/uploads/images-names`

// LEADERBOARD
export const ENDPOINT_GET_SCORES = `${API_DOMAIN}/scores/all`

// LOCALIZATION
export const LOCALES = ['it', 'en']

// GAMES
export const GAME_TYPES = [
  {
    value: 'riddle',
    label: 'Indovinello',
  },
  {
    value: 'singleChoiceQuiz',
    label: 'Quiz a risposta singola',
  },
  {
    value: 'multipleChoiceQuiz',
    label: 'Quiz a risposta multipla',
  },
  {
    value: 'timedQuiz',
    label: 'Quiz a tempo',
  },
  {
    value: 'photoQuiz',
    label: 'Quiz fotografico',
  },
  {
    value: 'drawingsAndWords',
    label: 'Disegni e parole',
  },
  {
    value: 'rebus',
    label: 'Rebus',
  },
  {
    value: 'hangman',
    label: 'Impiccato',
  },
  {
    value: 'reorderItems',
    label: 'Riordina gli elementi',
  },
  {
    value: 'reorderLetters',
    label: 'Riordina le lettere',
  },
]
