// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CCol, CModal, CModalBody, CRow, CSpinner } from '@coreui/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ItineraryFormCreate } from 'src/components/forms/create/itineraryFormCreate'
import { GameFields } from 'src/components/forms/gameFields'
import { MyRoutes } from 'src/routes'
import { del, get, patch } from 'src/services/http-service'
import {
  ENDPOINT_DELETE_ITINERARY,
  ENDPOINT_GET_ITINERARY_COMPLETE,
  ENDPOINT_PATCH_UPDATE_ITINERARY,
} from 'src/variables'

const EditItinerary = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [itinerary, setItinerary] = useState()
  const [editRequestState, setCreateRequestState] = useState('not_sent')
  const [isDeleteRequest, setIsDeleteRequest] = useState(false)
  const [deleteRequestState, setDeleteRequestState] = useState('not_sent')

  const navigate = useNavigate()
  const { itineraryId } = useParams()

  useEffect(() => {
    async function fetchItineraryFull() {
      // const decoded = atob()

      const res = await get({
        endpoint: `${ENDPOINT_GET_ITINERARY_COMPLETE}/${itineraryId}`,
        bearerToken: userToken,
      })

      console.log('edit itinerary fetch: ', res)
      setItinerary(fetchResponse2FormInput(res))
    }

    fetchItineraryFull()
  }, [])

  async function submit() {
    const reqBody = formOutput2EditBody(itinerary)
    console.log('submit: ', reqBody)

    setCreateRequestState('pending')

    try {
      await patch({
        endpoint: `${ENDPOINT_PATCH_UPDATE_ITINERARY}/${itineraryId}`,
        bearerToken: userToken,
        data: reqBody,
      })
      // console.log('res: ', res)
      setCreateRequestState('success')
    } catch (err) {
      // console.log('err: ', err)
      setCreateRequestState('failure')
    }
  }

  function gameConfiguration2Record(configuration, gameType) {
    const { id, title, description, hint, locale, isDefault, ...config } = configuration

    console.log('config: ', config)
    return {
      id,
      title,
      description,
      hint,
      config: JSON.stringify(
        GameFields[gameType].reduce(
          (acc, field) => ({
            ...acc,
            [field.name]: field.name === 'reward' ? Number(config[field.name]) : config[field.name],
          }),
          {},
        ),
      ),
      locale,
      isDefault,
    }
  }

  function formOutput2EditBody(itinerary) {
    let { locales, waypoints, ...rest } = itinerary

    let editBody = rest

    editBody.tags = editBody.tags.map((tag) => tag.value)
    waypoints = waypoints.map((waypoint) => {
      waypoint.games = waypoint.games.map((game) => {
        game.locales = game.locales.map((locale) => gameConfiguration2Record(locale, game.type))

        return game
      })

      return waypoint
    })

    editBody = {
      ...rest,
      locales: JSON.stringify(locales),
      waypoints: JSON.stringify(waypoints),
    }

    return editBody
  }
  function fetchResponse2FormInput(itinerary) {
    const { createdAt, updatedAt, deletedAt, ...formInput } = itinerary

    formInput.tags = formInput.tags.map((tag) => ({ value: tag.id, label: tag.locales[0].name }))
    formInput.locales = formInput.locales.map((locale) => {
      const { createdAt, updatedAt, deletedAt, ...localeRest } = locale

      return localeRest
    })

    formInput.waypoints = formInput.waypoints.map((waypoint) => {
      const { createdAt, updatedAt, deletedAt, ...waypointRest } = waypoint

      waypointRest.locales = waypointRest.locales.map((locale) => {
        const { createdAt, updatedAt, deletedAt, ...localeRest } = locale

        return localeRest
      })

      waypointRest.games = waypointRest.games.map((game) => {
        const { createdAt, updatedAt, deletedAt, ...gameRest } = game

        gameRest.locales = gameRest.locales.map((locale) => {
          const { createdAt, updatedAt, deletedAt, ...localeRest } = locale

          const { config, ...other } = localeRest
          const trasformed = {
            ...other,
            ...JSON.parse(config),
          }

          return trasformed
        })

        return gameRest
      })

      return waypointRest
    })

    return formInput
  }

  function toggleDeleteItineraryRequest() {
    setIsDeleteRequest(!isDeleteRequest)
  }

  async function deleteItinerary() {
    setDeleteRequestState('pending')

    try {
      const res = await del({
        endpoint: `${ENDPOINT_DELETE_ITINERARY}/${itineraryId}`,
        bearerToken: userToken,
      })

      if (res.status === 'success') {
        setDeleteRequestState('success')
      } else {
        setDeleteRequestState('failure')
      }
      console.log(res)
    } catch (err) {
      console.log(err)
      setDeleteRequestState('failure')
    }
  }

  const deleteModalBody =
    deleteRequestState === 'not_sent' ? (
      <>
        <CRow className="justify-content-center align-items-center">
          <CCol className="text-center">Sei sicuro di voler eliminare questo itinerario?</CCol>
        </CRow>
        <CRow className="justify-content-center align-items-center mt-5">
          <CCol className="text-center" sm={5}>
            <CButton variant="outline" className="w-100" onClick={toggleDeleteItineraryRequest}>
              No
            </CButton>
          </CCol>
          <CCol className="text-center" sm={5}>
            <CButton className="w-100" onClick={deleteItinerary}>
              Sí
            </CButton>
          </CCol>
        </CRow>
      </>
    ) : deleteRequestState === 'pending' ? (
      <CRow className="justify-content-center align-items-center">
        <CCol className="text-center">
          <CSpinner />
        </CCol>
      </CRow>
    ) : deleteRequestState === 'success' ? (
      <>
        <CRow className="justify-content-center align-items-center">
          <CCol className="text-center">Itinerario eliminato correttamente</CCol>
        </CRow>
        <CRow className="justify-content-center align-items-center mt-5">
          <CCol className="text-center" sm={8}>
            <CButton
              variant="outline"
              className="w-100"
              onClick={() => navigate(MyRoutes.DASHBOARD)}
            >
              Torna alla home
            </CButton>
          </CCol>
        </CRow>
      </>
    ) : (
      <>
        <CRow className="justify-content-center align-items-center">
          <CCol className="text-center">
            {"Non é stato possibile completare l'eliminazione dell'itinerario"}
          </CCol>
        </CRow>
        <CRow className="justify-content-center align-items-center mt-5">
          <CCol className="text-center" sm={8}>
            <CButton
              variant="outline"
              className="w-100"
              onClick={() => navigate(MyRoutes.DASHBOARD)}
            >
              Torna alla home
            </CButton>
          </CCol>
        </CRow>
      </>
    )

  return (
    <>
      <CButton
        color="danger"
        className="accordion-remove-button"
        onClick={toggleDeleteItineraryRequest}
      >
        <CIcon icon={cilTrash} />
      </CButton>
      {itinerary && (
        <ItineraryFormCreate initialValue={itinerary} onChange={setItinerary} onSubmit={submit} />
      )}
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={editRequestState !== 'not_sent'}
      >
        <CModalBody>
          <CRow className="justify-content-center align-items-center">
            <CCol className="text-center">
              {editRequestState === 'pending' ? (
                <CSpinner />
              ) : editRequestState === 'success' ? (
                <>
                  <p>Itinerario modificato con successo!</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              ) : (
                <>
                  <p>{`Non é stato possibile modificare l'itinerario`}</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              )}
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={isDeleteRequest}
      >
        <CModalBody>{deleteModalBody}</CModalBody>
      </CModal>
    </>
  )
}

export default EditItinerary
