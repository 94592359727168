import { CCol, CRow } from '@coreui/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MyCard } from 'src/components/MyCard'
import { MyRoutes } from 'src/routes'
import { get } from 'src/services/http-service'
import { ENDPOINT_GET_CREATED_ITINERARIES } from 'src/variables'

const Itineraries = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)

  const [itineraries, setItineraries] = useState([])

  const navigate = useNavigate()

  const onItineraryCardClick = (itinerary) => {
    // const encoded = itinerary.id.toString('base64')
    navigate(`${MyRoutes.EDIT_ITINERARY}/${itinerary.id}`)
  }

  const itinerariesHtml = itineraries
    .filter((item) => item.locales.length > 0) // TODO: remove this line, every itinerary should have at least one translation
    .map((itinerary, i) => (
      <CCol style={{ marginBottom: '15px' }} key={itinerary.id} sm={12} md={6} lg={4}>
        <MyCard heading={itinerary.locales[0].name} onClick={() => onItineraryCardClick(itinerary)}>
          <CRow>
            <CCol className="text-start" sm={6}>
              Cittá
            </CCol>
            <CCol className="text-end" sm={6}>
              {itinerary.city}
            </CCol>
          </CRow>
          <CRow>
            <CCol className="text-start" sm={6}>
              Costo
            </CCol>
            <CCol className="text-end" sm={6}>
              {itinerary.cost}
            </CCol>
          </CRow>
          <CRow>
            <CCol className="text-start" sm={6}>
              Lingue
            </CCol>
            <CCol className="text-end" sm={6}>
              {itinerary.locales.map((locale, i) => locale.locale).join(', ')}
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol>
              {itinerary.published ? (
                <div className="published-label">pubblicato</div>
              ) : (
                <div className="draft-label">bozza</div>
              )}
            </CCol>
          </CRow>
        </MyCard>
      </CCol>
    ))

  useEffect(() => {
    async function fetchItineraries() {
      const res = await get({ endpoint: ENDPOINT_GET_CREATED_ITINERARIES, bearerToken: userToken })
      console.log(res)
      setItineraries(res)
    }

    if (userToken) {
      fetchItineraries()
    }
  }, [userToken])

  return (
    <>
      <CRow>{itinerariesHtml}</CRow>
    </>
  )
}

export default Itineraries
