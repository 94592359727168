// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { CFormInput, CFormSelect, CSpinner } from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { merchantCategories } from 'src/models/merchant.models'
import { get } from 'src/services/http-service'
import { ENDPOINT_GET_MERCHANTS } from 'src/variables'
import { number, object } from 'yup'
import MerchantModal from './MerchantModal'
import MerchantsList from './MerchantsList'

const Merchants = () => {
  const userToken = useSelector((state) => state.user.token)
  const userEmail = useSelector((state) => state.user.email)
  const { t } = useTranslation()
  const [merchants, setMerchants] = useState([])
  const [fetchMerchantsRequestStatus, setFetchMerchantsRequestStatus] = useState('not_sent')
  const [merchantsError, setMerchantsError] = useState(false)
  const [merchantDetailsOpen, setMerchantDetailsOpen] = useState(false)
  const [selectedMerchant, setSelectedUser] = useState()
  const [sendBurnRequestStatus, setSendBurnRequestStatus] = useState('not_sent')
  /**
   * FILTERS
   */
  const [cityFilter, setCityFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState('')

  const resolverSchema = object({
    amount: number()
      .required(`La quantitá di token da bruciare é obbligatoria`)
      .min(1, 'La quantitá deve essere maggiore o uguale a 1'),
  }).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resolverSchema) })

  async function fetchMerchants() {
    setFetchMerchantsRequestStatus('pending')
    try {
      const merchantsPayload = await get({
        endpoint: ENDPOINT_GET_MERCHANTS,
        bearerToken: userToken,
      })

      if (!merchantsPayload.error) {
        setFetchMerchantsRequestStatus('success')
        setMerchants(merchantsPayload.merchants.filter((el) => el.user.email !== userEmail))
      } else {
        setFetchMerchantsRequestStatus('failure')
        setMerchantsError({ code: merchantsPayload.code })
      }
    } catch (err) {
      setFetchMerchantsRequestStatus('failure')
      setMerchantsError({ message: err?.message ?? err })
    }
  }

  useEffect(() => {
    fetchMerchants()
  }, [])

  useEffect(() => {
    if (merchantDetailsOpen) {
      setSendBurnRequestStatus('not_sent')
    }
  }, [merchantDetailsOpen])

  const selectOptions = merchantCategories.map((el) => ({
    label: t(`merchantCategories.${el}`),
    value: el,
  }))

  const filteredMerchants = merchants.filter(
    (el) =>
      el?.user?.city?.toLowerCase()?.startsWith(cityFilter.toLowerCase()) &&
      el?.category?.startsWith(categoryFilter),
  )

  return (
    <>
      {fetchMerchantsRequestStatus === 'success' ? (
        <>
          <div className="mb-5">
            <h3>Filtri</h3>
            <CFormInput
              label="Cittá"
              className="mb-2"
              onChange={(e) => setCityFilter(e.target.value)}
            ></CFormInput>
            <CFormSelect
              label="Categoria"
              options={['--Seleziona una categoria--', ...selectOptions]}
              onChange={(e) =>
                setCategoryFilter(
                  e.target.value === '--Seleziona una categoria--' ? '' : e.target.value,
                )
              }
            />
          </div>
          <h3>Partners</h3>
          <MerchantsList
            merchants={filteredMerchants}
            onItemClick={(el) => {
              setSelectedUser(el)
              setMerchantDetailsOpen(true)
            }}
          />
        </>
      ) : fetchMerchantsRequestStatus === 'pending' ? (
        <div className="d-flex justify-content-center">
          <CSpinner />
        </div>
      ) : fetchMerchantsRequestStatus === 'pending' ? (
        <></>
      ) : (
        <></>
      )}

      {selectedMerchant && (
        <MerchantModal
          isOpen={merchantDetailsOpen}
          selectedMerchant={selectedMerchant}
          onClose={() => setMerchantDetailsOpen(false)}
        />
      )}
    </>
  )
}

export default Merchants
