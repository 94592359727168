import { cilLockLocked } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CLink,
  CRow,
  CSpinner,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MyRoutes } from 'src/routes'
import { post } from 'src/services/http-service'
import { ENDPOINT_RESET_PASSWORD } from 'src/variables'
import { object, ref, string } from 'yup'

const ResetPassword = () => {
  const { token } = useParams()
  const { t } = useTranslation()
  const [resetError, setResetError] = useState(false)
  const [resetRequestStatus, setResetRequestStatus] = useState('not_sent')

  const resolverSchema = object({
    password: string()
      .required('Campo mancante')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
        'Minimo 8 caratteri, 1 lettera maiuscola, 1 minuscola e 1 numero',
      ),
    confirmPassword: string()
      .required('Campo mancante')
      .oneOf([ref('password')], 'La password non corrisponde'),
  }).required('Campo mancante')
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resolverSchema) })

  const submit = async (e) => {
    try {
      setResetRequestStatus('pending')
      const resetPayload = await post({
        endpoint: ENDPOINT_RESET_PASSWORD,
        data: {
          token: token,
          newPassword: e.password,
        },
      })

      if (!resetPayload.error) {
        setResetRequestStatus('success')
      } else {
        setResetRequestStatus('failure')
        setResetError({ code: resetPayload.code })
      }
    } catch (err) {
      setResetRequestStatus('failure')
      setResetError({ message: err?.message ?? err })
      // setLoginCompleted(true)
      // TODO: to handle
    }
  }

  const form = (
    <CForm onSubmit={handleSubmit(submit)}>
      <h1>{t('resetPassword.title')}</h1>
      <p className="text-medium-emphasis">{t('resetPassword.sub')}</p>
      <CInputGroup className="mb-3">
        <CInputGroupText>
          <CIcon icon={cilLockLocked} />
        </CInputGroupText>

        <Controller
          render={({ field }) => {
            return (
              <>
                <CFormInput
                  type="password"
                  placeholder="Password"
                  autoComplete="password"
                  onChange={field.onChange}
                />
                <CCol xs={12}>
                  <span>{errors[field.name]?.message}</span>
                </CCol>
              </>
            )
          }}
          name="password"
          control={control}
        />
      </CInputGroup>
      <CInputGroup className="mb-3">
        <CInputGroupText>
          <CIcon icon={cilLockLocked} />
        </CInputGroupText>

        <Controller
          render={({ field }) => {
            return (
              <>
                <CFormInput
                  type="password"
                  placeholder="Confirm Password"
                  autoComplete="confirmPassword"
                  onChange={field.onChange}
                />
                <CCol xs={12}>
                  <span>{errors[field.name]?.message}</span>
                </CCol>
              </>
            )
          }}
          name="confirmPassword"
          control={control}
        />
      </CInputGroup>

      <div className="d-grid">
        <CButton type="submit" color="success">
          {t('labels.send')}
        </CButton>
      </div>
    </CForm>
  )

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody
                className={{ 'p-4': true, 'centered-ctn': resetRequestStatus !== 'not_sent' }}
              >
                {resetRequestStatus === 'not_sent' ? (
                  form
                ) : resetRequestStatus === 'pending' ? (
                  <CSpinner style={{ margin: 'auto' }} />
                ) : resetRequestStatus === 'success' ? (
                  <>
                    <CCardText>{t('resetPassword.requestSuccess')}</CCardText>
                    <CLink href={MyRoutes.LOGIN}>
                      <CButton>Login</CButton>
                    </CLink>
                  </>
                ) : (
                  <CCardText>
                    {resetError?.code ? t(`api.${resetError.code}`) : resetError?.message}
                  </CCardText>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword
