// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const MerchantsList = ({ merchants, onItemClick }) => {
  const { t } = useTranslation()

  return (
    <CTable striped hover bordered color="light">
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell scope="col">{"Username (nome dell'attivitá)"}</CTableHeaderCell>
          <CTableHeaderCell scope="col">Email</CTableHeaderCell>
          <CTableHeaderCell scope="col">Cittá</CTableHeaderCell>
          <CTableHeaderCell scope="col">Categoria</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {merchants.map((el, index) => (
          <CTableRow
            key={index}
            onClick={() => {
              if (onItemClick) {
                onItemClick(el)
              }
            }}
          >
            <CTableHeaderCell scope="row">{el.user.username}</CTableHeaderCell>
            <CTableDataCell>{el.user.email}</CTableDataCell>
            <CTableDataCell>{el.user.city}</CTableDataCell>
            <CTableDataCell>{t(`merchantCategories.${el.category}`)}</CTableDataCell>
          </CTableRow>
        ))}
      </CTableBody>
    </CTable>
  )
}

MerchantsList.propTypes = {
  merchants: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
}

export default MerchantsList
