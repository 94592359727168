export const merchantCategories = [
  'hotels',
  'restaurants',
  'souvenirs',
  'food',
  'travel',
  'touristAttractions',
  'tourGuides',
  'wellness',
  'administration',
  'other',
]
