import { createSlice } from '@reduxjs/toolkit'
import { AUTH_TOKEN_KEY } from 'src/variables'

// export const UserContext = {
//   user: {
//     status: UserStatus.FETCHING,
//   },
//   login: () => {},
// }

export const UserStatus = {
  LOGGED: 'logged',
  NOT_LOGGED: 'not_logged',
  FETCHING: 'fetching',
}

const initialState = {
  status: UserStatus.NOT_LOGGED,
  id: undefined,
  email: undefined,
  username: undefined,
  token: undefined,
  balance: undefined,
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      localStorage.setItem(AUTH_TOKEN_KEY, action.payload.token)
      state.status = UserStatus.LOGGED
      state.id = action.payload.id
      state.email = action.payload.email
      state.username = action.payload.username
      state.token = action.payload.token
      // window.location.href = '#/dashboard'
    },
    logout: (state) => {
      localStorage.removeItem(AUTH_TOKEN_KEY)
      state.status = UserStatus.NOT_LOGGED
      state.id = undefined
      state.email = undefined
      state.username = undefined
      state.token = undefined
      console.log('logout')
    },
    setBalance(state, action) {
      state.balance = action.payload
    },
  },
})

export const { login, logout, setBalance } = userSlice.actions
export default userSlice.reducer
