import { cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import 'simplebar/dist/simplebar.min.css'
import { get, patch } from 'src/services/http-service'
import {
  ENDPOINT_GET_TAGS,
  LOCALES,
  ENDPOINT_GET_ITINERARY_STRIPE,
  ENDPOINT_PATCH_UPDATE_ITINERARY_STRIPE,
} from 'src/variables'
import { v4 } from 'uuid'
import { object } from 'yup'
import { validateItineraryForm, validationMap } from '../formUtils'
import { TranslationForm } from '../translationForm'
import { WaypointFormCreate } from './waypointFormCreate'
import STRIPE from '../../../assets/images/stripe.png'

export const ItineraryFormCreate = ({ initialValue, onSubmit, onChange }) => {
  const userToken = useSelector((state) => state.user.token)
  // const [itinerary, setItinerary] = useState({
  //   id: initialValue?.id ?? -1,
  //   cost: initialValue?.cost ?? -1,
  //   city: initialValue?.city ?? '',
  //   waypoints: initialValue?.waypoints ?? [],
  //   tags: initialValue?.tags ?? [],
  //   locales: initialValue?.locales ?? [],
  // })

  const formId = v4()

  const [availableTags, setAvailableTags] = useState([])
  const [tagsFetchState, setTagsFetchState] = useState('not_fetched')
  const [waypoints, setWaypoints] = useState(initialValue?.waypoints ?? [])
  const [translations, setTranslations] = useState(initialValue?.locales ?? [])
  const [alertMsg, setAlertMsg] = useState('')
  const [stripeAccount, setStripeAccount] = useState({
    account: initialValue?.stripe_account ?? '',
    link: initialValue?.stripe_account_link ?? '',
    onBoardingComplete: initialValue?.stripe_account_on_boarding_complete ?? false,
  })
  const [stripeStatus, setStripeStatus] = useState('')

  const resolverSchema = object({
    id: validationMap.number.required(),
    cost: validationMap.number
      .min(0, 'Il costo deve essere maggiore o uguale a 0')
      .required('Il costo é un campo obbligatorio'),
    city: validationMap.string.required('La cittá é un campo obbligatorio'),
    tags: validationMap.array
      .min(1, "L'itinerario deve avere almeno un tag")
      .required('I tag sono un campo obbligatorio'),
  }).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resolverSchema),
    defaultValues: {
      id: initialValue?.id ?? -1,
      published: initialValue?.published ?? false,
      cost: initialValue?.cost ?? -1,
      city: initialValue?.city ?? '',
      tags: initialValue?.tags ?? [],
    },
  })

  const watchFormFields = useWatch({ control })

  useEffect(() => {
    async function fetchTags() {
      setTagsFetchState('pending')
      const res = await get({ endpoint: ENDPOINT_GET_TAGS, bearerToken: userToken })
      // console.log(res)
      setAvailableTags(res)
      setTagsFetchState('fetched')
    }

    fetchTags()
    getStripeAccount()
  }, [])

  async function getStripeAccount() {
    if (stripeAccount.account && stripeAccount.onBoardingComplete) await getStripeDashboard()
    if (stripeAccount.link && !stripeAccount.dashboard) await createStripeCustomer()
  }

  // function onTagSelectValueChange(value) {
  //   console.log('ITINERARY - tag selection change', value)
  // }

  const tagSelectOptions = availableTags.map((tag) => ({
    value: tag.id,
    label: tag.locales[0].name,
  }))

  const translationItems = [
    {
      name: 'name',
      label: 'Titolo itinerario',
      type: 'string',
    },
    {
      name: 'description',
      label: 'Descrizione itinerario',
      type: 'textarea',
    },
    {
      name: 'recap',
      label: 'Riepilogo itinerario',
      type: 'string',
    },
  ]

  useEffect(() => {
    onChange({
      ...watchFormFields,
      waypoints,
      locales: translations,
    })
  }, [watchFormFields, waypoints, translations])

  useEffect(() => {
    if (alertMsg) alert(alertMsg)
  }, [alertMsg])

  function onTranslationFormChange(index, data) {
    // console.log('ITINERARY - translation form change', index, data)
    const newTranslations = [...translations]
    newTranslations[index] = data
    setTranslations(newTranslations)
  }

  function removeTranslation(index) {
    let newTranslations = [...translations]
    newTranslations.splice(index, 1)
    setTranslations(newTranslations)
  }

  function addTranslation() {
    if (translations.length < LOCALES.length) {
      // console.log('ITINERARY - adding new translation')
      setTranslations([...translations, {}])
    }
  }
  function addWaypoint() {
    // console.log('ITINERARY - adding new waypoint')
    setWaypoints([...waypoints, null])
  }

  function onWaypointChange(index, data) {
    // console.log('ITINERARY - waypoint submit', index, data)
    const newWaypoints = [...waypoints]
    newWaypoints[index] = data
    setWaypoints(newWaypoints)
  }

  function removeWaypoint(index) {
    let newWaypoints = [...waypoints]
    newWaypoints.splice(index, 1)
    setWaypoints(newWaypoints)
  }

  function submitItineraryForm(v) {
    const validationRes = validateItineraryForm({
      ...v,
      locales: translations,
      waypoints,
    })

    // console.log({
    //   ...v,
    //   locales: translations,
    //   waypoints,
    // })
    // console.log(validationRes)

    if (!validationRes) {
      onSubmit({
        ...v,
        locales: translations,
        waypoints,
      })
    } else {
      setAlertMsg(validationRes)
    }
  }

  async function createStripeCustomer(click = null) {
    if (stripeStatus !== 'loading') {
      setStripeStatus('loading')
      try {
        let link = await patch({
          endpoint: `${ENDPOINT_PATCH_UPDATE_ITINERARY_STRIPE.replace('{id}', initialValue.id)}`,
          bearerToken: userToken,
          data: {},
        })
        setStripeStatus('success')
        let acc = stripeAccount
        acc.link = link.stripe_link
        setStripeAccount(acc)
        getStripeLink(click)
      } catch {
      } finally {
        setStripeStatus('')
      }
    }
  }

  async function getStripeDashboard() {
    if (stripeStatus !== 'loading') {
      setStripeStatus('loading')
      try {
        let link = await get({
          endpoint: `${ENDPOINT_GET_ITINERARY_STRIPE.replace('{id}', initialValue.id)}`,
          bearerToken: userToken,
          data: {},
        })
        let acc = stripeAccount
        acc.dashboard = link.link
        setStripeAccount(acc)
      } catch {
      } finally {
        setStripeStatus('')
      }
    }
  }

  function getStripeLink(click = null) {
    if (!stripeAccount.dashboard && stripeStatus !== 'loading') {
      if (stripeAccount.link && click === true) window.open(stripeAccount.link, '_blank')
      if (!stripeAccount.link) createStripeCustomer(click)
    }
  }

  const translationsAccordionItems = translations.map((translation, index) => (
    <CAccordionItem key={index}>
      <CAccordionHeader>
        {translation?.locale ? `${index} - ${translation?.locale}` : `Traduzione n.${index}`}
      </CAccordionHeader>
      <CAccordionBody>
        <CButton
          color="danger"
          variant="outline"
          className="accordion-remove-button"
          onClick={() => removeTranslation(index)}
        >
          <CIcon icon={cilTrash} />
        </CButton>
        <TranslationForm
          locales={LOCALES}
          defaultLocale={LOCALES[index]}
          initialValue={translation}
          items={translationItems}
          onChange={(data) => onTranslationFormChange(index, data)}
        ></TranslationForm>
      </CAccordionBody>
    </CAccordionItem>
  ))
  const waypointsAccordionItems = waypoints.map((waypoint, index) => (
    <CAccordionItem key={index}>
      <CAccordionHeader>
        {waypoint?.name ? `${index} - ${waypoint?.name}` : `Tappa n.${index}`}
      </CAccordionHeader>
      <CAccordionBody>
        <CButton
          color="danger"
          variant="outline"
          className="accordion-remove-button"
          onClick={() => removeWaypoint(index)}
        >
          <CIcon icon={cilTrash} />
        </CButton>
        <WaypointFormCreate
          initialValue={waypoint}
          onChange={(data) => onWaypointChange(index, data)}
        />
      </CAccordionBody>
    </CAccordionItem>
  ))

  return (
    <>
      <CForm id={formId} onSubmit={handleSubmit(submitItineraryForm)}>
        {initialValue?.id && (
          <CRow className="my-5">
            <h2>Pagamenti</h2>
            {stripeStatus === 'loading' && <CSpinner className="ms-4" />}
            {!stripeAccount.dashboard && stripeStatus !== 'loading' && (
              <div>
                <p>Collega un account Stripe per ricevere pagamenti da Zonzo</p>
                {stripeAccount.link && (
                  <p>
                    Vai a{' '}
                    <a rel="noreferrer" target="_blank" href={stripeAccount.link}>
                      questo link
                    </a>{' '}
                    se non vieni reindirizzato automaticamente o aggiorna la pagina
                  </p>
                )}
                <img
                  src={STRIPE}
                  className="stripe-btn"
                  onClick={() => {
                    getStripeLink(true)
                  }}
                />
              </div>
            )}
            {stripeAccount.dashboard && stripeStatus !== 'loading' && (
              <div>
                <p>Hai già collegato il tuo account Stripe</p>
                <CButton
                  className="form-add-subitem-button"
                  onClick={() => {
                    console.log(stripeAccount)
                    window.open(stripeAccount.dashboard, '_blank')
                  }}
                >
                  Vai alla tua dashboard
                </CButton>
              </div>
            )}
          </CRow>
        )}
        <div>
          {initialValue?.id && <h2>Itinerario</h2>}
          <Controller
            render={({ field }) => {
              return (
                <>
                  <CFormLabel htmlFor="cost-field">Costo</CFormLabel>
                  <CFormInput
                    defaultValue={field.value}
                    id="cost-field"
                    type="number"
                    onChange={field.onChange}
                  />
                  <CCol xs={12}>
                    <span>{errors[field.name]?.message}</span>
                  </CCol>
                </>
              )
            }}
            name="cost"
            control={control}
          />
          <Controller
            render={({ field }) => {
              return (
                <>
                  <CFormLabel htmlFor="city-field">Cittá</CFormLabel>
                  <CFormInput
                    defaultValue={field.value}
                    id="city-field"
                    type="string"
                    onChange={field.onChange}
                  />
                  <CCol xs={12}>
                    <span>{errors[field.name]?.message}</span>
                  </CCol>
                </>
              )
            }}
            name="city"
            control={control}
          />
          <Controller
            render={({ field }) => {
              return (
                <>
                  <span>Tags</span>
                  {tagsFetchState === 'fetched' && (
                    <Select
                      isMulti
                      defaultValue={field.value}
                      options={tagSelectOptions}
                      onChange={field.onChange}
                    />
                  )}
                  <CCol xs={12}>
                    <span>{errors[field.name]?.message}</span>
                  </CCol>
                </>
              )
            }}
            name="tags"
            control={control}
          />
        </div>
      </CForm>
      <h4 className="mt-3">Traduzioni itinerario</h4>
      <CButton onClick={addTranslation} className="form-add-subitem-button">
        <CIcon icon={cilPlus} /> Aggiungi traduzione
      </CButton>
      <CAccordion>{translationsAccordionItems}</CAccordion>
      <h4 className="mt-3">Tappe</h4>
      <CButton className="form-add-subitem-button" onClick={addWaypoint}>
        <CIcon icon={cilPlus} /> Aggiungi tappa
      </CButton>
      <CAccordion>{waypointsAccordionItems}</CAccordion>
      {onSubmit && (
        <CButton className="itinerary-form-submit-button" form={formId} type="submit">
          Salva
        </CButton>
      )}
    </>
  )
}

ItineraryFormCreate.propTypes = {
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}
