import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import it from './locales/it/translations.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'it',
  lng: 'it',
  resources: {
    it: {
      translations: it,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
})

i18n.languages = ['it', 'en']

export default i18n
