// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import {
  CButton,
  CCardText,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { get, post } from 'src/services/http-service'
import { ENDPOINT_GET_CHECK_RECEIPT, ENDPOINT_TRANSFER_TO } from 'src/variables'
import { number, object } from 'yup'

const MerchantModal = ({ isOpen, selectedMerchant, onClose }) => {
  const { t } = useTranslation()
  const userToken = useSelector((state) => state.user.token)
  const userBalance = useSelector((state) => state.user.balance)
  const [sendTransferRequestStatus, setSendTransferRequestStatus] = useState('not_sent')
  const [transferError, setTransferError] = useState(false)
  const [redirectTimeout, setRedirectTimeout] = useState(5)

  const countersThreshold = 15

  const resolverSchema = object({
    amount: number()
      .required(`La quantitá di token da inviare é obbligatoria`)
      .min(1, 'La quantitá deve essere maggiore o uguale a 1')
      .max(userBalance, 'Non puoi inviare piú token di quanti ne possiedi'),
  }).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resolverSchema),
    defaultValues: {
      amount: 1,
    },
  })

  useEffect(() => {
    if (isOpen) {
      setSendTransferRequestStatus('not_sent')
      reset()
    }
  }, [isOpen])

  const submit = async (e) => {
    try {
      setSendTransferRequestStatus('pending')
      const transferPayload = await post({
        endpoint: ENDPOINT_TRANSFER_TO,
        bearerToken: userToken,
        data: {
          amount: e.amount,
          userId: selectedMerchant.user.id,
        },
      })

      if (!transferPayload.error) {
        setSendTransferRequestStatus('waiting')
        let transferTxStatus = 'pending'
        let transferTxCheckCounter = 0
        while (
          (transferTxStatus === 'pending' || transferTxStatus === 'error') &&
          transferTxCheckCounter < countersThreshold
        ) {
          await sleep(4000)
          transferTxStatus = await checkReceipt(transferPayload.tx)
        }

        if (transferTxStatus === 'success') {
          setSendTransferRequestStatus('success')
          let redirectTimer = 5
          setRedirectTimeout(redirectTimer)
          const redirectInterval = window.setInterval(() => {
            if (redirectTimer > 1) {
              redirectTimer--
              setRedirectTimeout(redirectTimer)
            } else {
              window.clearInterval(redirectInterval)
              window.location.reload()
            }
          }, 1000)
        } else if (transferTxStatus === 'pending') {
          setSendTransferRequestStatus('failure')
          setTransferError({
            message:
              'La transazione é ancora in corso. Per favore, ritorna piú tardi per verificare lo stato del trasferimento.',
          })
        } else {
          setSendTransferRequestStatus('failure')
          setTransferError({ message: 'La transazione é fallita. Per favore, riprova piú tardi.' })
        }
      } else {
        setSendTransferRequestStatus('failure')
        setTransferError({ code: transferPayload.code })
      }
    } catch (err) {
      setSendTransferRequestStatus('failure')
      setTransferError({ message: err?.message ?? err })
      // setLoginCompleted(true)
      // TODO: to handle
    }
  }

  async function checkReceipt(tx) {
    try {
      const res = await get({
        endpoint: `${ENDPOINT_GET_CHECK_RECEIPT}/${tx}`,
        bearerToken: userToken,
      })
      return res
    } catch (err) {
      return 'error'
    }
  }

  async function sleep(ms) {
    await new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }

  return (
    <>
      <CModal
        keyboard={false}
        visible={isOpen}
        onClose={() => {
          if (onClose) {
            if (
              sendTransferRequestStatus !== 'pending' &&
              sendTransferRequestStatus !== 'waiting'
            ) {
              onClose()
            }
          }
        }}
        alignment="center"
      >
        <CModalHeader>
          <CModalTitle>{selectedMerchant?.merchantname}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer>
            <CRow>
              <CCol sm={3}>Attivitá:</CCol>
              <CCol sm={9}>{selectedMerchant?.user?.username}</CCol>
            </CRow>
            <CRow>
              <CCol sm={3}>Email:</CCol>
              <CCol sm={9}>{selectedMerchant?.user?.email}</CCol>
            </CRow>
            <CRow className="mt-3">
              <CCol sm={12}>
                <h3>Invia token</h3>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm={12}>
                <CForm onSubmit={handleSubmit(submit)}>
                  <CInputGroup>
                    <Controller
                      render={({ field }) => {
                        return (
                          <>
                            <CFormInput
                              disabled={true}
                              placeholder="Quantitá"
                              type="number"
                              autoComplete="quantitá"
                              value={1}
                              onChange={field.onChange}
                            />
                            <CCol xs={12}>
                              <span>{errors[field.name]?.message}</span>
                            </CCol>
                          </>
                        )
                      }}
                      name="amount"
                      control={control}
                    />
                  </CInputGroup>
                  {sendTransferRequestStatus === 'not_sent' && (
                    <CButton type="submit" className="w-50 mt-3 mx-auto d-block">
                      Invia
                    </CButton>
                  )}
                  {sendTransferRequestStatus === 'pending' && (
                    <div className="w-100 mt-3 d-flex flex-column justify-content-center align-items-center">
                      <CCardText>
                        Per favore, attendi mentre la richiesta viene processata...
                      </CCardText>
                      <CSpinner />
                    </div>
                  )}
                  {sendTransferRequestStatus === 'waiting' && (
                    <p className="w-100 mt-3 text-center">In attesa della blockchain...</p>
                  )}
                  {sendTransferRequestStatus === 'success' && (
                    <p className="w-100 mt-3 text-center">
                      Token inviati con successo, la pagina verrá ricaricata in {redirectTimeout}
                    </p>
                  )}
                  {sendTransferRequestStatus === 'failure' && (
                    <p className="w-100 mt-3 text-center">
                      {transferError?.code
                        ? t(`api.${transferError?.code}`)
                        : transferError?.message}
                    </p>
                  )}
                </CForm>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm={12}></CCol>
            </CRow>
          </CContainer>
        </CModalBody>
      </CModal>
    </>
  )
}

MerchantModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedMerchant: PropTypes.object,
  onClose: PropTypes.func,
}

export default MerchantModal
