// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import {
  CFormInput,
  CFormSelect,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'src/services/http-service'
import { ENDPOINT_GET_SCORES } from 'src/variables'
import { ENDPOINT_GET_CREATED_ITINERARIES } from 'src/variables'

const Leaderboard = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [scores, setScores] = useState([])
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [sortMethod, setSortMethod] = useState('none')
  const [itineraries, setItineraries] = useState([])

  // const resolverSchema = object({
  //   email: string().required().email(),
  // }).required()
  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({ resolver: yupResolver(resolverSchema) })

  useEffect(() => {
    async function fetchItineraries() {
      const res = await get({ endpoint: ENDPOINT_GET_CREATED_ITINERARIES, bearerToken: userToken })
      let ids = res.map((el) => el.id)
      console.log('User ids:', ids)
      setItineraries(ids)
    }

    async function fetchScores() {
      const res = await get({
        endpoint: ENDPOINT_GET_SCORES,
        bearerToken: userToken,
      })

      setScores(
        res.map((el) => ({
          ...el,
          createdAtTimestamp: new Date(el.createdAt).getTime(),
          createdAtFormatted: moment(el.createdAt).format('LLL'),
        })),
      )
    }
    if (userToken) {
      fetchScores()
      fetchItineraries()
    }
  }, [userToken])

  // const submit = async (e) => {
  //   const res = await post({
  //     endpoint: ENDPOINT_POST_SEND_REFERRAL,
  //     bearerToken: userToken,
  //     data: {
  //       tokenValue: selectedCode.value,
  //       recipient: e.email,
  //     },
  //   })
  // }

  let filteredScores = scores.filter((score) => {
    let res = true
    // console.log(score)
    if (minDate !== null && score.createdAtTimestamp < minDate) res = false
    if (maxDate !== null && score.createdAtTimestamp > maxDate) res = false
    if (itineraries.indexOf(score.itinerary?.id) === -1) res = false
    return res
  })

  if (sortMethod !== 'none') {
    filteredScores = filteredScores.sort((a, b) => b.value - a.value)
  }

  return (
    <div>
      <div className="leaderboard-filters-ctn">
        <h3>Filtri</h3>
        <CFormInput
          label="Data di inizio"
          type="date"
          className="mb-3 date-filter"
          onChange={(e) =>
            setMinDate(e.target.value === '' ? null : new Date(e.target.value).getTime())
          }
        ></CFormInput>
        <CFormInput
          label="Data di fine"
          type="date"
          className="mb-3 date-filter"
          onChange={(e) =>
            setMaxDate(e.target.value === '' ? null : new Date(e.target.value).getTime())
          }
        ></CFormInput>
      </div>
      {/* <CFormCheck label="Ordina per punteggio"></CFormCheck> */}
      <div className="leaderboard-sort-ctn mt-4">
        <h3>Ordina per</h3>
        <CFormSelect
          onChange={(e) => setSortMethod(e.target.value)}
          options={[
            { label: 'DATA', value: 'none' },
            { label: 'PUNTEGGIO', value: 'score' },
          ]}
        ></CFormSelect>
      </div>

      <div className="leaderboard-sort-ctn mt-4">
        <h3>Punteggi</h3>
        <CTable striped hover bordered color="light" style={{ tableLayout: 'fixed' }}>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">Punteggio</CTableHeaderCell>
              <CTableHeaderCell scope="col">User</CTableHeaderCell>
              <CTableHeaderCell scope="col">Itinerario</CTableHeaderCell>
              <CTableHeaderCell scope="col">Data</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {filteredScores.map((score, index) => (
              <CTableRow key={index}>
                <CTableHeaderCell scope="row">{score.value}</CTableHeaderCell>
                <CTableDataCell style={{ overflowX: 'scroll' }}>{score.user.email}</CTableDataCell>
                <CTableDataCell style={{ overflowX: 'scroll' }}>
                  {score.itinerary?.locales?.[0]?.name}
                </CTableDataCell>
                <CTableDataCell style={{ overflowX: 'scroll' }}>
                  {score.createdAtFormatted}
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      </div>
    </div>
  )
}

export default Leaderboard
