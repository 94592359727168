import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import 'simplebar/dist/simplebar.min.css'
import { validateWaypointForm, validationMap } from 'src/components/forms/formUtils'
import { LOCALES } from 'src/variables'
import { v4 } from 'uuid'
import { object } from 'yup'
import { GameFormCreate } from './gameFormCreate'
import { TranslationForm } from '../translationForm'

export const WaypointFormCreate = ({ initialValue, onSubmit, onChange }) => {
  // const userToken = useSelector((state) => state.user.token)
  // const [waypoint, setWaypoint] = useState({
  //   id: initialValue?.id ?? -1,
  //   itineraryOrder: initialValue?.itineraryOrder ?? -1,
  //   locales: initialValue?.locales ?? [],
  //   games: initialValue?.games ?? [],
  // })

  const formId = v4()

  const [games, setGames] = useState(initialValue?.games ?? [])
  const [translations, setTranslations] = useState(initialValue?.locales ?? [])
  const [alertMsg, setAlertMsg] = useState('')

  const resolverSchema = object({
    id: validationMap.number.required(),
    itineraryOrder: validationMap.number.required(),
  }).required()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resolverSchema),
    defaultValues: {
      id: initialValue?.id ?? -1,
      itineraryOrder: initialValue?.itineraryOrder ?? -1,
    },
  })

  const watchFormFields = useWatch({ control })

  const translationItems = [
    {
      name: 'name',
      label: 'Titolo tappa',
      type: 'string',
    },
    {
      name: 'anecdote',
      label: 'Aneddoto',
      type: 'string',
    },
    {
      name: 'anecdoteHint',
      label: 'Dettaglio aneddoto',
      type: 'string',
    },
    {
      name: 'waypointIndication',
      label: 'Indicazioni tappa',
      type: 'textarea',
    },
    {
      name: 'waypointMapLink',
      label: 'Link alla mappa',
      type: 'string',
    },
    {
      name: 'waypointSponsorName',
      label: 'Nome sponsor',
      type: 'string',
    },
    {
      name: 'waypointSponsorLogo',
      label: 'Link al logo sponsor',
      type: 'string',
    },
    {
      name: 'waypointSponsorLink',
      label: 'Link al sito dello sponsor',
      type: 'string',
    },
  ]

  useEffect(() => {
    onChange({
      ...watchFormFields,
      games,
      locales: translations,
    })
  }, [watchFormFields, games, translations])

  useEffect(() => {
    if (alertMsg) alert(alertMsg)
  }, [alertMsg])

  function onTranslationFormChange(index, data) {
    // console.log('WAYPOINT - translation form change', index, data)
    const newTranslations = [...translations]
    newTranslations[index] = data
    setTranslations(newTranslations)
  }

  function addTranslation() {
    if (translations.length < LOCALES.length) {
      // console.log('WAYPOINT - adding new translation')
      setTranslations([...translations, {}])
    }
  }
  function addGame() {
    // console.log('WAYPOINT - adding new game')
    setGames([...games, null])
  }

  function onGameChange(index, data) {
    // console.log('WAYPOINT - game change', index, data)
    const newGames = [...games]
    newGames[index] = data
    setGames(newGames)
  }

  function submitWaypointForm(v) {
    const validationRes = validateWaypointForm({
      ...v,
      locales: translations,
      games,
    })

    if (!validationRes) {
      onSubmit({
        ...v,
        locales: translations,
        games,
      })
    } else {
      setAlertMsg(validationRes)
    }
  }

  const translationsAccordionItems = translations.map((translation, index) => (
    <CAccordionItem key={index}>
      <CAccordionHeader>
        {translation?.locale ? `${index} - ${translation?.locale}` : `Traduzione n.${index}`}
      </CAccordionHeader>
      <CAccordionBody>
        <TranslationForm
          locales={LOCALES}
          defaultLocale={LOCALES[index]}
          initialValue={translation}
          items={translationItems}
          onChange={(data) => onTranslationFormChange(index, data)}
        ></TranslationForm>
      </CAccordionBody>
    </CAccordionItem>
  ))
  const gamesAccordionItems = games.map((game, index) => (
    <CAccordionItem key={index}>
      <CAccordionHeader>
        {game?.name ? `${index} - ${game?.name}` : `Gioco n.${index}`}
      </CAccordionHeader>
      <CAccordionBody>
        <GameFormCreate initialValue={game} onChange={(data) => onGameChange(index, data)} />
      </CAccordionBody>
    </CAccordionItem>
  ))

  return (
    <>
      <CForm id={formId} onSubmit={handleSubmit(submitWaypointForm)}>
        <Controller
          render={({ field }) => {
            return (
              <>
                <CFormLabel htmlFor="itineraryOrder-field">Numero tappa</CFormLabel>
                <CFormInput
                  defaultValue={field.value}
                  id="itineraryOrder-field"
                  type="string"
                  onChange={field.onChange}
                />
                <CCol xs={12}>
                  <span>{errors[field.name]?.message}</span>
                </CCol>
              </>
            )
          }}
          name="itineraryOrder"
          control={control}
        />
      </CForm>
      <h4>Traduzioni tappa</h4>
      <CButton onClick={addTranslation}>
        <CIcon icon={cilPlus} /> Aggiungi traduzione
      </CButton>
      <CAccordion>{translationsAccordionItems}</CAccordion>
      <h4>Giochi</h4>
      <CButton className="form-add-subitem-button" onClick={addGame}>
        <CIcon icon={cilPlus} /> Aggiungi gioco
      </CButton>
      <CAccordion>{gamesAccordionItems}</CAccordion>
      {onSubmit && (
        <CButton form={formId} type="submit">
          Salva
        </CButton>
      )}
    </>
  )
}

WaypointFormCreate.propTypes = {
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
}
